/* Form container basic styling */
.form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 90vh;
    justify-content: space-between;
    background-color: #1b1b1b;
    padding: 50px 20px;
    box-sizing: border-box;
    border-radius: 20px;
    /* 3d effect */
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
    text-align: center;
    /* margin: 20px; */
    margin: 20px 0;
    position: relative;
}

form {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    width: 70%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    position: absolute;
    transition: all 0.3s ease;
}

/* Button Group Styling */
.button-group {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.button-group button {
    width: 8%;
    margin: 0;
    padding: 0;
    padding: 5px;
}

.form-header {
    text-align: center;
    /* position: absolute;
    top: 20px; */
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 2em;
}

/* button:hover {
    background-color: #2980b9;
} */

/* Animation Classes for Next/Previous Steps */
.slide-next-enter {
    opacity: 0;
    transform: translateX(50%);
}

.slide-next-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: all 300ms ease;
}

.slide-next-exit {
    opacity: 1;
    transform: translateX(0);
}

.slide-next-exit-active {
    opacity: 0;
    transform: translateX(-50%);
    transition: all 300ms ease;
}

.slide-prev-enter {
    opacity: 0;
    transform: translateX(-50%);
}

.slide-prev-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: all 300ms ease;
}

.slide-prev-exit {
    opacity: 1;
    transform: translateX(0);
}

.slide-prev-exit-active {
    opacity: 0;
    transform: translateX(50%);
    transition: all 300ms ease;
}

/* Optional Transition Effects - Success Page */
.success-message {
    text-align: center;
    color: #2ecc71;
    font-weight: bold;
}

/* A focus on typography (modern fonts & clear size) */
form label {
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
    font-weight: 500;
    color: #333333;
    width: 60%;
    margin-right: 10px;
    align-self: center;
}

/* form input[type="text"],
form input[type="email"],
form input[type="password"],
form input[type="file"],
form input[type="date"],
form select {
    padding: 12px 15px;
    margin-bottom: 20px;
    border: 1px solid #e1e1e1;
    border-radius: 10px;
    width: 100%;
    box-sizing: border-box;
    font-size: 1rem;
    color: #333;
    background-color: #f9f9f9;
    outline: none;
    transition: all 0.3s ease;
    display: block;
    margin: 5px auto;
} */

form input[type="text"],
form input[type="email"],
form input[type="password"],
form input[type="file"],
form input[type="date"],
form select {
    padding: 15px 20px;
    margin-bottom: 20px;
    border: 1px solid #e1e1e1;
    border-radius: 10px;
    width: 100%;
    box-sizing: border-box;
    font-size: 1rem;
    color: #333;
    background-color: #f9f9f9;
    outline: none;
    transition: all 0.3s ease;
    display: block;
    margin: 5px auto;
}

/* Focused input fields */
form input:focus,
form select:focus {
    border-color: #3498db;
    box-shadow: 0 4px 8px rgba(52, 152, 219, 0.1);
    background-color: #fff;
}

/* Error message styling */
form div>div {
    color: #e74c3c;
    font-size: 0.85rem;
}

/* Modern button styles with subtle 3D effect */
button {
    /* background-color: #3498db; */
    color: #000;
    padding: 12px 15px;
    font-size: 1.1rem;
    cursor: pointer;
    border: none;
    border-radius: 8px;
    width: 100%;
    transition: all 0.3s ease;
    margin-bottom: 10px;
}

/* Hover and active states */
button:hover {
    background-color: #e4e4e4;
    box-shadow: 0 6px 12px rgba(52, 152, 219, 0.2);
    color: #000;
}

button:active {
    transform: translateY(3px);
    box-shadow: 0 4px 8px rgba(52, 152, 219, 0.15);
}

/* Disabled button state */
button:disabled {
    background-color: #ddd;
    cursor: not-allowed;
}

/* Previous and Next button styles */
button[type="button"] {
    /* background-color: #6bbef5; */
    color: #fff;
    font-family: 'Montserrat', sans-serif;
}

/* button[type="button"]:hover {
    background-color: #3c61db;
} */

/* Add transition effects for form fields */
@keyframes fadein {
    from {
        opacity: 0;
        transform: translateY(10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

form>div {
    animation: fadein 0.4s ease;
}

/* Optional: Add some accent color visuals */
.progress-indicator {
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 0.95rem;
    color: #3498db;
}

.success-message {
    text-align: center;
    color: #2ecc71;
    /* Success green */
}

.error-message {
    text-align: center;
    color: #e74c3c;
    /* Error red */
}

@media (min-width: 768px) {
    form label {
        font-family: 'Montserrat', sans-serif;
        font-size: 1.1rem;
    }

    .form-container {
        min-height: 80vh;
    }

    form {
        width: 100%;
    }

    .form-header {
        text-align: left;
        font-size: 5.5em;
        margin: 0;
    }

    form label {
        width: 40%;
    }

    .form-container {
        margin: 0;
    }
}