@media screen and (max-width: 800px),
screen and (max-height: 780px) {
    .phone-input-container {
        width: 100%;
    }

    .floating-input {
        width: 70% !important;
        font-size: 14px;
        margin-left: 30px !important;
    }

    .phone-input-container .flag-dropdown {
        width: 50px;
    }

    .phone-input-container .form-control {
        padding-left: 60px;
    }

    .otp-container {
        width: 100%;
        gap: 0 !important;
    }

    .otp-box {
        width: 30px !important;
        font-size: 16px !important;
        margin-left: 5px !important;
        margin-right: 5px !important;
        color: black;
    }
}