/* InstaProfile.css */

:root {
    --primary-color: #333;
    --secondary-color: #888;
    --border-color: #ddd;
    --background-color: #fff;
    --box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    --font-family: Arial, sans-serif;
    --highlight-color: #053f79;
}

.profile-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px;
    max-width: 500px;
    border: 1px solid var(--border-color);
    border-radius: 12px;
    background-color: var(--background-color);
    box-shadow: var(--box-shadow);
    margin: 10px auto;
    font-family: var(--font-family);
}

.profile-header {
    display: flex;
    align-items: center;
    width: 100%;
}

.profile-pic {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 3px solid var(--highlight-color);
    object-fit: cover;
}

.profile-stats {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-left: 20px;
}

.stat-item {
    text-align: center;
    flex: 1;
}

.stat-number {
    font-weight: 700;
    font-size: 1.25rem;
    margin: 5px 0;
    color: var(--primary-color);
}

.stat-label {
    color: var(--secondary-color);
    font-size: 0.875rem;
}

.profile-info {
    text-align: center;
    margin: auto;
    width: 80%;
}

.full-name {
    font-size: 0.75rem;
    font-weight: 700;
    margin: 0;
    color: var(--primary-color);
    text-align: left;
}

.username {
    font-size: 0.9rem;
    color: var(--highlight-color);
    font-weight: 500;
    margin: 5px 0;
}