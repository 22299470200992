.getStartedBtn {
    text-decoration: none;
    border: 1px solid #000;
    background-color: #000;
    margin: auto;
    color: #fff !important;
}

.getStartedBtn:hover {
    background-color: #fff;
    color: #000 !important;
}

.form-white {
    width: 700px;
    height: 300px;
    /* align everthing to vertical center */
    justify-content: space-between;
    position: relative;
}

/* .prev-btn-form {
    margin: 0;
    padding: 0;
    width: 8%;
    position: absolute;
    bottom: 20px;
    left: 20px;
}

.next-btn-form {
    margin: 0;
    padding: 0;
    width: 8%;
    position: absolute;
    bottom: 20px;
    right: 20px;
} */

.prev-btn-form,
.next-btn-form {
    padding: 5px 10px;
}

/* Media query for larger screens */
@media screen and (max-width: 800px),
screen and (max-height: 780px) {

    .form-white {
        width: 105%;
        height: 300px;
        padding: 0;
    }

    .prev-btn-form,
    .next-btn-form {
        width: auto;
        bottom: 10px;
        padding: 5px;
    }

    .form-container {
        min-height: 60vh;
    }

    /* for the inputs */
    .form-group {
        width: 100%;
    }

}