.Brands {
  font-family: "Montserrat", sans-serif;
  background-color: #1b1b1b;
  color: #333;
  /* height: 100vh; */
  overflow: hidden;
}

.navbar {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #1b1b1b;
  color: #fff;
}

.navbar-logo {
  font-weight: bold;
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.marquee {
  width: 100%;
  overflow: hidden;
  margin-bottom: 10px;
}

.navbar-buttons {
  display: flex;
  justify-content: flex-end;
}

.get-started {
  background-color: #000;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 100px;
  color: #fff;
  border: solid 3px #fff;
  font-weight: 600;
}

.main-section {
  padding: 20px;
}

.main-content {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 30px;
  padding: 20px;
}

.left-section,
.right-section {
  width: 100%;
  margin-bottom: 20px;
}

.main-image,
.side-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
  align-self: center;
  display: block;
  margin: auto;
}

.main-image {
  width: 100%;
}

.side-image {
  object-fit: contain;
}

.middle-section {
  padding: 0;
}

.middle-heading-brands {
  font-size: 1.8rem;
  color: #333;
  font-weight: 700;
  line-height: 1.2em;
  margin-bottom: 20px;
}

.middle-section p {
  font-size: 1rem;
  color: #666;
  margin-bottom: 30px;
}

.register-btn button {
  background-color: #222;
  color: #fff;
  font-size: 1.2rem;
  width: 100%;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  padding: 15px;
  font-family: "Montserrat", sans-serif;
}

.stats-container {
  display: flex;
  justify-content: space-between;
  padding: 25px 5px;
  border-radius: 8px;
}

/* .stats-item {
  text-align: center;
  flex-grow: 1;
  align-content: center;
  border-right: 2px solid #ddd;
}

.stats-item:last-child {
  border-right: none;
}

.stats-label {
  font-size: 0.8rem;
  color: #333;
  font-weight: 400;
  text-align: center;
  margin-bottom: 5px;
  margin-left: 5px;
  height: 2em;
  align-items: center;
  display: flex;
}

.stats-value {
  font-size: 1.3rem;
  color: #1b1b1b;
  font-weight: 600;
  text-align: center;
} */

/* Slide-in transitions */
.slide-enter {
  transform: translateY(100%);
  opacity: 0;
}

.slide-enter-active {
  transform: translateY(0);
  opacity: 1;
  transition: transform 900ms ease-in-out, opacity 900ms ease-in-out;
}

.slide-fast-enter {
  transform: translateY(100%);
  opacity: 0;
}

.slide-fast-enter-active {
  transform: translateY(0);
  opacity: 1;
  transition: transform 600ms ease-in-out, opacity 600ms ease-in-out;
}

.slide-left-enter {
  transform: translateX(-100%);
  opacity: 0;
}

.slide-left-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: transform 800ms ease-in-out, opacity 800ms ease-in-out;
}

.slide-right-enter {
  transform: translateX(100%);
  opacity: 0;
}

.slide-right-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: transform 800ms ease-in-out, opacity 800ms ease-in-out;
}

.slide-exit {
  transform: translateX(0);
  opacity: 1;
}

.slide-exit-active {
  transform: translateX(100%);
  opacity: 0;
  transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
}

/* Media query for larger screens */
@media (min-width: 768px) {
  .navbar {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px 50px;
  }

  .navbar-logo {
    margin-bottom: 0;
  }

  .marquee {
    width: auto;
    margin-bottom: 0;
  }

  .main-content {
    flex-direction: row;
    padding: 35px;
  }

  .left-section,
  .right-section {
    width: 25%;
    margin-bottom: 0;
  }

  .middle-section {
    flex: 2;
    padding: 0 50px;
  }

  .middle-heading-brands {
    font-size: 5rem;
    line-height: 1em;
  }

  .middle-section p {
    font-size: 1.3rem;
  }

  .register-btn button {
    width: 50%;
  }

  .stats-container {
    flex-direction: row;
    justify-content: space-between;
  }

  /* 
  .stats-item {
    border-bottom: none;
    border-right: 2px solid #ddd;
    padding: 0 20px;
  }

  .stats-label {
    font-size: 1.5em;
    margin: 0;
    display: block;
  }

  .stats-value {
    font-size: 2.5em;
  } */

  .stats-item:last-child {
    border-right: none;
  }

  .Brands {
    height: 100vh;
  }

  .main-content {
    height: 80vh;
  }
}

/* Spinner.css */
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}