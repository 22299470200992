.floating-label-input-container {
    position: relative;
    margin-top: 10px;
    width: 100%;
    display: flex;
    height: 50px;
}

.floating-label {
    position: absolute;
    left: 10px;
    top: 8px;
    width: 100%;
    font-size: 16px;
    color: grey;
    transition: all 0.2s ease-out;
    pointer-events: none;
    font-family: "Montserrat", sans-serif;
    text-align: left;
    height: 40px;

}

.focused {
    top: -12px;
    font-size: 12px;
    color: black;
}

.floating-input {
    width: 100%;
    padding: 20px 10px !important;
    border: none !important;
    border-bottom: 2px solid #ccc !important;
    border-radius: 0 !important;
    background-color: #fff !important;
    height: 0px !important;
}

.floating-input:focus {
    border-color: white !important;
    box-shadow: none;
}


.floatingEmail-icon {
    position: absolute;
    background-color: #fff;
    right: -10px;
    top: 15px;
    border-radius: 100px;
    align-self: center;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: grey;
    cursor: pointer;
}

.country-code-dropdown {
    border: 2px solid #ccc;
    border-radius: 5px;
    padding: 5px 0;
    font-size: 16px;
    background-color: white;
    color: grey;
    width: 75%;
    height: 40px;
    font-family: "Montserrat", sans-serif;
}

.otp-box {
    border: none;
    border-bottom: 2px solid #ccc;
    background-color: #fff;
    outline: none;
    transition: border-color 0.2s ease-in-out;
}

.otp-box:focus {
    border-color: black;
}