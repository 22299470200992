.page-wrapper {
    /* min-height: 100vh; */
    display: flex;
    flex-direction: column;
}

.main-content {
    flex: 1;
    padding: 40px 0;
}

.contact-container {
    display: flex;
    max-width: 1200px;
    min-height: calc(100vh - 200px);
    /* Adjust based on navbar and footer height */
    margin: 0 auto;
    padding: 0 20px;
    gap: 110px;
    font-family: "Montserrat", sans-serif;
}

input,
input::placeholder,
textarea,
textarea::placeholder {
    font-family: "Montserrat", sans-serif;
}

.contact-form-section {
    flex: 1;
    max-width: 600px;
}

.get-in-touch {
    color: #000;
    font-size: 16px;
    margin-bottom: 16px;
    display: block;
}

h1 {
    font-size: 48px;
    margin: 0 0 16px;
    font-weight: 600;
    line-height: 1.2;
}

.description {
    color: #666;
    margin-bottom: 32px;
    font-size: 16px;
    line-height: 1.5;
}

.contact-form {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 80%;
    position: static !important;
}

.name-row {
    display: flex;
    gap: 24px;
}

.form-group {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.form-group label {
    font-weight: 500;
    color: #333;
}

.contact-form-section .form-group label,
.contact-form-section form label {
    width: 100%;
}

.form-group input,
.form-group textarea {
    padding: 12px 16px;
    border: 1px solid #E5E7EB;
    border-radius: 8px;
    background-color: #F9FAFB;
    font-size: 16px;
    width: 100%;
}

.form-group textarea {
    resize: vertical;
    min-height: 120px;
    width: 93%;
}

.privacy-policy {
    display: flex;
    align-items: center;
    gap: 8px;
}

.privacy-policy input[type="checkbox"] {
    width: 20px;
    height: 20px;
    border: 1px solid #E5E7EB;
    border-radius: 4px;
}

.privacy-policy a {
    color: #1f27c3;
    text-decoration: underline;
}

.contact-info-section {
    /* flex: 1; */
    display: flex;
    flex-direction: column;
    /* gap: 32px; */
    width: 40%;
}

.image-container {
    background: #EEF2FF;
    border-radius: 16px;
    overflow: hidden;
    /* margin: auto; */
    margin-top: 20px;
    margin-bottom: 20px;
}

.image-container img {
    width: 100%;
    height: auto;
    display: block;
}

.contact-details {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.contact-item {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 24px;
    background: #F9FAFB;
    border-radius: 12px;
}

.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    background: #EEF2FF;
    border-radius: 50%;
    color: #000;
}

.contact-text h3 {
    margin: 0 0 4px;
    font-size: 18px;
    font-weight: 500;
}

.contact-text p {
    margin: 0;
    color: #666;
}

.submit-button {
    background-color: #000;
    color: white;
    padding: 14px 28px;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;
}

.submit-button:hover {
    background-color: #777777;
}

.submit-button:active {
    transform: translateY(1px);
}

@media (max-width: 768px) {
    .contact-container {
        flex-direction: column;
        padding: 20px;
        gap: 32px;
    }

    .name-row {
        flex-direction: column;
    }

    .contact-form-section {
        max-width: 100%;
    }

    h1 {
        font-size: 36px;
    }

    .contact-form {
        gap: 16px;
    }

    .contact-item {
        padding: 16px;
        width: 100%;
    }

    .contact-info-section {
        width: 85%;
    }


    .form-group input,
    .form-group textarea {
        padding: 10px 14px;
    }

    .main-content {
        padding: 20px 0;
    }
}

@media (max-width: 480px) {
    h1 {
        font-size: 28px;
    }

    .get-in-touch {
        font-size: 14px;
    }

    .description {
        font-size: 14px;
    }
}