.glitch-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    overflow: hidden;
}

.glitch-text {
    color: white;
    font-family: sans-serif;
    font-size: 3rem;
    font-weight: bold;
    position: relative;
    display: inline-block;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
}

.glitch-text::before,
.glitch-text::after {
    content: "lynkd";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.glitch-text::before {
    left: 2px;
    text-shadow: -2px 0 #ff00c1;
    clip: rect(24px, 550px, 90px, 0);
    animation: glitch-anim-1 1ms infinite linear alternate-reverse;
}

.glitch-text::after {
    left: -2px;
    text-shadow: -2px 0 #00fff9, 2px 2px #ff00c1;
    animation: glitch-anim-2 500ms infinite linear alternate-reverse;
}

@keyframes glitch-anim-1 {
    0% {
        clip: rect(86px, 9999px, 42px, 0);
    }

    20% {
        clip: rect(38px, 9999px, 95px, 0);
    }

    40% {
        clip: rect(81px, 9999px, 36px, 0);
    }

    60% {
        clip: rect(34px, 9999px, 33px, 0);
    }

    80% {
        clip: rect(91px, 9999px, 90px, 0);
    }

    100% {
        clip: rect(90px, 9999px, 66px, 0);
    }
}

@keyframes glitch-anim-2 {
    0% {
        clip: rect(15px, 9999px, 100px, 0);
    }

    20% {
        clip: rect(19px, 9999px, 34px, 0);
    }

    40% {
        clip: rect(6px, 9999px, 62px, 0);
    }

    60% {
        clip: rect(89px, 9999px, 6px, 0);
    }

    80% {
        clip: rect(82px, 9999px, 29px, 0);
    }

    100% {
        clip: rect(56px, 9999px, 98px, 0);
    }
}